var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "header",
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "30px",
      "height": "30px",
      "margin-top": "4px",
      "margin-right": "8px"
    },
    attrs: {
      "src": require("@/assets/doors.png"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "title"
  }, [_vm._v("地磅称重管理系统")])])]);
}];
export { render, staticRenderFns };