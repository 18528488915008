export default {
  name: "langSwitch",
  data() {
    return {
      currLang: "zh-CN"
    };
  },
  methods: {
    langChange(v) {
      this.currLang = v;
      this.$i18n.locale = v;
      this.$store.commit("switchLang", v);
    }
  },
  mounted() {
    if (localStorage.lang) {
      this.currLang = localStorage.lang;
    }
  }
};