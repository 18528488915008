var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "register-result"
  }, [_c('Row', {
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "type": "flex",
      "justify": "center",
      "align": "middle"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submitRegist.apply(null, arguments);
      }
    }
  }, [_c('Col', {
    staticClass: "content"
  }, [_c('div', [_c('Header'), _c('div', {
    staticClass: "success"
  }, [_c('Icon', {
    attrs: {
      "type": "md-checkmark-circle",
      "color": "#52c41a",
      "size": "64"
    }
  }), _c('p', {
    staticClass: "success-words"
  }, [_vm._v("恭喜您，您的账户：" + _vm._s(_vm.username) + " 注册成功")]), _c('div', {
    staticClass: "buttons"
  }, [_c('router-link', {
    attrs: {
      "to": "/login"
    }
  }, [_c('Button', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "type": "primary",
      "size": "large"
    }
  }, [_vm._v("立即登录")])], 1), _c('router-link', {
    attrs: {
      "to": "/register"
    }
  }, [_c('Button', {
    attrs: {
      "size": "large"
    }
  }, [_vm._v("返回注册")])], 1)], 1)], 1)], 1), _c('Footer')], 1), _c('LangSwitch')], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };